.crossword {
  width: 800px;
}

.buttonTaras{
  /* height: 75px;
  width: 200px;
  background-color: #F7F2FF;
  border: 2px solid #937DB3;
  border-radius: 50px;
  margin-bottom: 2rem; */
  margin-bottom: 2rem;
  justify-content: center;
}

.button {
  height: 100%;
  width: 75%;
  background-color: #F7F2FF;
  border: 2px solid #937DB3;
  border-radius: 50px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}