.tests{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    z-index: 100;

    .tests__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;

        .tests__score{
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;

            h3{
                font-size: 2.15rem;
            }
        }
    
        .tests__content{
    
            .tests__progress_bar{
    
                .quest__progress_number{
                    border: 2px solid rgba(122,214,255,1);
                    width: 130px;
                    margin-bottom: 1rem;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                }
                .progress__bar{
    
                }
            }
        }
    
        .test__questions{
    
            .question__block{
                
                h2{
                    text-align: center;
                }
                .answers__block{
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;

                    .answer{
                        // width: 300px;
                        width: 100%;
                        height: 150px;
                        min-height: 100px;
                        padding: 0 40px;
                        margin: 2rem;
                        border-radius: 50px;
                        border: 1px solid rgba(122,214,255,1);
                        background-color: #F6FDFF; 
                        display: flex;
                        align-items: center;
                        text-align: center;

                        @media screen and (max-width: 500px) {
                            padding: 0 20px;
                        }

                        a{
                            font-size: calc(16px + 6 * ((100vw - 320px) / 680));
                            width: 100%;
                            height: 100%;
                            display: flex;
                        }
                 

                        
                        .answers{
                            height: 100%;
                            width: 100%;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;
                            cursor: default;
                        }

                        a:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        } 
    }   
}

.tests__fortune{

    .tests__container{

        .tests__score{
            .btn{
                border: 1px solid #9DFF7A;
                background-color: #F9FFF9;  
                border-radius: 50px;
                height: 4rem;
                font-size: 1.5rem;
            }
        }
    
        .tests__content{

            .tests__progress_bar{
                
                .quest__progress_number{
                    border: 2px solid #9DFF7A;

                }
            }     
        }
        
        .test__questions{
    
            .question__block{
    
                .answers__block{

                    .answer{
                        border: 1px solid #9DFF7A;
                        background-color: #F9FFF9; 

                        a{
                            font-size: calc(10px + 6 * ((100vw - 320px) / 680)); 
                        }
                    }
                }
            }
        } 
    }   
  }

.close{
    display: none;
}

@media screen and (max-width: 800px) {
    .tests{
        overflow-y: scroll;
        padding-top: 5rem;

        .tests__container{
            
            .tests__content{
                height: 90vh;
            }

            .test__questions{
        
                .question__block{
        
                    .answers__block{
                        margin-top: 2rem;
                        overflow-x: hidden;
                        flex-direction: column;
                        align-items: center;

                        .answer__block{
                            margin: 0 0 2rem 0;
                        }

                        .answer{
                            width: 350px;
                            height: 75px;
                            border-radius: 25px;
                            margin: 0;
                        }
                    }
                }
            } 
        }   
    }
}

@media screen and (max-width: 500px) {
    .tests{
        padding-top: 0rem;
    }
}

@media screen and (max-width: 400px) {
    .tests{
    
        .tests__container{

            .test__questions{
        
                .question__block{
        
                    .answers__block{

                        .answer{
                            width: 315px;
                        }
                    }
                }
            } 
        }   
    }
}

@media screen and (max-width: 350px) {
    .tests{
    
        .tests__container{

            .tests__score{

                h2{
                    font-size: 2rem;
                }
            }

            .test__questions{
        
                .question__block{
        
                    .answers__block{

                        .answer{
                            width: 245px;
                        }
                    }
                }
            } 
        }   
    }
}