.success__oops{
    background: linear-gradient(261.43deg, #B9E1FF 2.23%, #FFECA7 96.69%);
    width: 800px;
    height: 336px;
    margin-top: 2rem;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;

    h1{
        font-size: 3.3rem;
    }

    .button{
        margin-top: 3rem;
        border-radius: 50px;
        border: 2px solid black;
        width: 197px;
        height: 52px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 1024px) {
    .success__oops{
        width: 700px;
    
        h1{
            font-size: 3rem;
        }
    }
}

@media screen and (max-width: 800px) {
    .success__oops{
        width: 650px;
    
        h1{
            font-size: 2.7rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .success__oops{
        width: 345px;
        height: 300px;
        padding: 2rem;
        

        h1{
            font-size: 1.4rem;
        }

        .button{
            height: 45px;
        }
    }
}

@media screen and (max-width: 350px) {
    .success__oops{
        width: 300px;
    }
}