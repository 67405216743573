.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 100%;

  .overflow {
    transition: 1s;
    display: flex;
    align-items: flex-start;
    height: 421.34px;

    &.isRoll {
      border-radius: 50%;
      height: 300px;
      overflow: hidden;
      transform: scale(1.5);
    }
  }

  .content {
    display: flex;
    width: 100%;
    max-width: 300px;
    transition: 1s;
  }

  &.isRoll {
    opacity: 0;
    pointer-events: none;
  }
  
}

@media screen and (max-width: 768px) {
  .container {
  
    .overflow {
      
      &.isRoll {
        transform: scale(1.2);
      }
    }
  }  
}

@media screen and (max-width: 500px) {
  .wheelFortune{
    height: 790px;
  }
}

@media screen and (max-width: 350px) {
  .container {
  
    .overflow {
      
      &.isRoll {
        transform: scale(0.95);
      }
    }
  }  
}
