$black: #000000;
$blue: #7AD6FF;
$lightblue: #F3FBFF;

.preview {
  padding: 0 0 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;

  @media screen and (max-width: 428px) {
    padding: 0;
  }

  .preview__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .preview__text{
      margin: 0 0 10% 0;

      h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
        font-weight: bold;
        background: linear-gradient(90.25deg, rgba(255, 206, 109, 0.78) 50%, rgba(255, 232, 109, 0) 100%);
        border-radius: 50px;
        padding: 1rem;
        text-align: center;
      }

      .preview__text_p {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
      }
    }

    .divButton{
      display: flex;

      .button__link {
        position: relative;
        z-index: 0;
        width: 331px;
        height: 52px;
        overflow: hidden;
        border: none !important;
        padding: 0 !important;
        background: #F3FBFF !important;
        color: black;
        background: white;
        border-radius: 50px;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 400;
        // box-shadow: 0 4px 50px 0 rgb(0 0 0 / 7%);
        transition: .2s all linear;
        text-decoration: initial;
      
        span{
          position: relative;
          z-index: 1;
          height: calc( 100% - 4px );
          width: calc( 100% - 4px );
          align-items: center;
          display: flex;
          justify-content: center;
          border-radius: 50px;
          color: black;
          background: #F3FBFF;
        }
      }
      
      .button__link:after{
       content: "";
        position: absolute;
        z-index: -2;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image: linear-gradient(90deg, rgba(255,255,255,0)    0%, #7AD6FF 50%, rgba(255,255,255,0) 100%);
        -webkit-animation: rotate 2s linear infinite;
        animation: rotate 2s linear infinite;
      }
      
      
      @-webkit-keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
      }
      
      @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
      }
    //   .button__link {
    //     /* reset */
    //     -webkit-appearance: none;
    //     border: 0;
    //     outline: 0;
    //     height: 52px;
    //     width: 257px;
    //     /* styles */
    //     position: relative;
    //     background: linear-gradient(-45deg, #ffd6d6,#faffb0,#c0ffb6,#d0ddff,#e1bfff);
    //     background-size: 400% 400%;
    //     animation: gradientBG 4.5s ease infinite;
    //     padding: 1.5rem 10rem;
    //     border-radius: 50px;
    //     border: 1px solid $blue;
    //     color: black;
    //     text-transform:uppercase;
    //     font-size: 1.3rem;
    //     transition: .3s;
    //     cursor: pointer;
    //   }

    //   .button__link:active{
    //     background-color: $lightblue;
    //   }

    //   @keyframes gradientBG {
    //    0% {
    //      background-position: 0% 50%;
    //    }
    //    50% {
    //      background-position: 100% 50%;
    //    }
    //    100% {
    //      background-position: 0% 50%;
    //    }
    //   }  
    }
  }

  .preview__move{
    display: flex;
    justify-content: center;
  }
}



@media screen and (max-width: 1440px) {
  .preview {
    margin: 50px 0 0 0; 
  }
}

@media screen and (max-width: 1024px) {
  .preview {
    margin: 25px 0 0 0;

    .preview__content{

      .preview__text{

        h2{
          font-size: 1.75rem;
          margin-bottom: 1rem;
        }

        .preview__text_p{
          font-size: 1.1rem;
          margin-bottom: 1rem;
        }

        p{
          font-size: 1rem;
        }
      }

      .divButton{

        .button__link{
          height: 46px;
          width: 230px;
          font-size: 1.2rem;
        }
      }
    }
  }

}

@media screen and (max-width: 820px) {
  .preview {

    .preview__content {

      .preview__text{

        h2{
          font-size: 1.65rem;
        }
      }

      .divButton{

        .button__link{
          height: 40px;
          width: 225px;
          font-size: 1.15rem;
        }
      }
    }
  }
}



@media screen and (max-width: 750px){
  .preview{
    flex-direction: column-reverse;
    margin-bottom: 2rem;

    .preview__content {

      .preview__text {
        margin: 0 0 7% 0;

        h2{
          font-size: 1.65rem;
          margin-bottom: 0.7rem;
        }

        .preview__text_p{
          font-size: 1.1rem;
          margin-bottom: 0.5rem;
        }
      }

      .divButton{
        // display: none; 
        .button__link{
          width: 100%;
        }
      }
    }

    .preview__move{
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width:450px) {
  .preview{

    .preview__text h2{
      font-size: 2.05rem;
    }
  }
}

@media screen and (max-width: 428px) {
  .preview {
    margin-top: 0.7rem;
    margin-bottom: 0;

    .preview__text {
      margin: 0 0 10% 0;
    }

    .preview__text h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }
}

@media screen and (max-width: 390px) {
  .preview{

    .preview__text h2{
      font-size: 1.9rem;
    }
  }
}

@media screen and (max-width: 375px) {
  .preview {

    .preview__text h2 {
      font-size: 1.75rem;
    }
  }
}

@media screen and (max-width: 320px) {
  .preview{

    .preview__content{

      .preview__text{
        h2{
          font-size: 1.6rem;
        }
  
        p{
          font-size: 0.9rem;
        }
      }
    }
  }
}
