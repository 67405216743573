.container__taras{
    position: relative;
    height: 97vh;
}

.success__page {
    margin: 55px 0 150px 0;
    height: 100%;
    // min-height: 100vh;

    .success__title {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 37px;
        letter-spacing: 0.5px;
        color: #000000;
        display: flex;

        .heart{
            height: 32px;
            width: 32px;
        }
    }

    .success__text {
        margin: 1rem 0 1rem 0;
        margin-bottom: 1rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.7rem;
        letter-spacing: 0.5px;
        color: #000000;
        width: 875px;
        text-align: center;
    }

    .certificate{
        width: 1030px; 
        height: 628px;
        margin: 2rem 0 2rem 0;
    }

    .success__course{
        margin: 1.2rem 0 1rem 0;
    }

    .success__footer{
        justify-content: space-around;
        // margin-top: auto;

        .success__mail_send{
            justify-content: center;

            .input{
                color: black;
                margin: 1rem;
                width: 453px;
                height: 52px;
                border-radius: 50px;
                border: 1px solid #937DB3;
                background-color: transparent;
                padding-left: 2rem;
                font-size: 1.5rem;
              }
        }

        .success__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F7F2FF;
            border: 1px solid #937DB3;
            border-radius: 26px;
            font-style: normal;
            cursor: pointer;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.5px;
            color: #000000;
            height: 52px;
            width: 250px;
        }

        .success__down{
            justify-content: center;
            // margin-left: 1rem;
        }

    }
}

@media screen and (max-width: 1024px) {
    .success__page {
        margin-top: 25px;
    
        .success__title {
            font-size: 2rem;
        }
    
        .success__text {
            width: 700px;
            font-size: 1.2rem;
            margin: 0.5rem 0 1.5rem 0;
        }

        .certificate{
            width: 700px;
            height: 475px;
            margin: 1.5rem 0 1.5rem 0;
        }
    
        .success__footer{
            margin-bottom: 100px;

            .success__mail_send{

                .input{
                    width: 275px;
                    padding-left: 2rem;
                    font-size: 1.2rem;
                  }
            }
    
            .success__btn {
                font-size: 1rem;
                width: 200px;
            }

    
        }
    }
}

@media screen and (max-width: 500px) {
    .success__page {
        margin-top: 15px;
    
        .success__title {
            font-size: 1.75rem;
        }
    
        .success__text {
            width: 350px;
            font-size: 1rem;
        }

        .certificate{
            height: 335px;
            margin: 1.5rem 0 1.5rem 0;
        }
    
        .success__footer{
            margin-bottom: 75px;
            flex-direction: column;

            .success__mail_send{
                flex-direction: column;
                margin-bottom: 1rem;

                .input{
                    width: 355px;
                }

                .success__down{
                    margin-top: 1rem;
                }
            }
    
            .success__btn {
                width: 355px;
            }
    
        }
    }
}

@media screen and (max-width: 350px) {
    .success__page {
    
        .success__title {
            font-size: 1.5rem;
        }
    
        .success__text {
            width: 300px;
        }

        .certificate{
            height: 250px;
            margin: 1rem 0 1rem 0;
        }
    
        .success__footer{

            .success__mail_send{

                .input{
                    width: 300px;
                  }
            }
    
            .success__btn {
                width: 300px;
            }
    
        }
    }
}