.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, .85);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modalContainer {
  width: 96%;
  height: 90vh;
  border-radius: 50px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    
    button{
      font-size: 1.5rem;
      background: none;
    }

    a {
      background-color: transparent;
      text-decoration: none;
      border: none;
      font-size: 25px;
      cursor: pointer;
    }
  } 

}

@media screen and (max-width: 500px) {
  .modalContainer{
    padding: 15px;

    .titleCloseBtn {
      
      button {
        margin-right: 1rem;
      }
    } 
  }

  .modalContainer{
    width: 100%;
    height: 100vh;
    border-radius: 0;
    padding: 15px;
    box-shadow: none;
  }
}